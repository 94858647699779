import { Injectable } from '@angular/core';
import { ServiceInfoResponse } from '../models/discovery/ServiceInfoResponse';
import { DiscoverResponse } from '../models/discovery/DiscoverResponse';
import { StorageService } from './storage.service';

/**
 * Service for storing and fetching discovery objects
 */
@Injectable({
  providedIn: 'root'
})
export class ServiceInfoManager {

  reloadStorageKey: string = 'reload';
  discoveryStorageKey: string = 'discovery';
  serverStorageKey: string = 'serverName';
  storageService: StorageService = new StorageService();

  constructor() { }

  /**
   * Stores the discovery response in localStorage
   * @param discovery discovery response
   */
  public storeDiscovery(discovery: DiscoverResponse) {
    let discoveryString = JSON.stringify(discovery);
    this.storageService.store(this.discoveryStorageKey, discoveryString);
  }

  /**
   * Fetches the list of services in the discovery response from localStorage
   * @returns ServiceInfo list
   */
  public getServices(): ServiceInfoResponse[] {
    let discoveryString = this.storageService.retrieve(this.discoveryStorageKey);
    if(discoveryString && discoveryString != 'undefined') {
      let discovery = JSON.parse(discoveryString) as DiscoverResponse;

      if(discovery) {
        return discovery.services;
      }
    }

    return [];
  }

  /**
   * Finds a specific service based on the target service's name
   * @param serviceName target service name
   * @returns ServiceInfo object
   */
  public getServiceByName(serviceName: string): ServiceInfoResponse | undefined {
    return this.getServices().find(x => x.name == serviceName);
  }

  /**
   * Stores current server name in localStorage
   * @param serverName name of the current server
   */
  public storeServerName(serverName: string) {
    this.storageService.store(this.serverStorageKey, serverName);
  }

  /**
   * Fetch current server name from local storage
   * @returns name of the current server
   */
  public getServerName(): string {
    return this.storageService.retrieve(this.serverStorageKey) || '';
  }

  /**
   * Stores current reload count in localStorage
   * @param serverName reload count
   */
   public storeReloadCount(reloadCount: number) {
    this.storageService.store(this.reloadStorageKey, reloadCount.toString());
  }

  /**
   * Fetch current reload count from local storage
   * @returns reload count
   */
  public getReloadCount(): number {
    return parseInt(this.storageService.retrieve(this.reloadStorageKey) || '0');
  }
}
