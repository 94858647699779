/**
 * App settings for configuration values
 */
 export class AppSettings {
  /**
   * Global Login URL
   */
   globalIdentityUrl: string = ''

   /**
    * Scenario website URL
    */
   scenarioCloudUrl: string = 'https://scenariocloud.com'
}
