import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiClientHelperService } from './api-client-helper.service';
import { ServiceInfoManager } from './service-info-manager.service';
import { SaveAttachmentRequest } from '../models/file/SaveAttachmentRequest';
import { Observable, of, lastValueFrom, from, mergeMap, catchError, concat } from 'rxjs';
import { GetEmailByIdRequest } from '../models/file/GetEmailByIdRequest';
import { GetEmailByIdResponse } from '../models/file/GetEmailByIdResponse';
import { request } from 'http';
import { nanoid } from 'nanoid';

/**
 * Service for interacting with files like attachments, etc.
 */
@Injectable({
  providedIn: 'root'
})
export class FileService {

  fileUrl: string = '';
  controller: string = 'Attachments';
  takeCount: number = 10;
  apiResource: (_: string) => string = () => '';

  constructor(@Inject(HttpClient) private http: HttpClient,
              @Inject(ServiceInfoManager) private serviceInfoManager: ServiceInfoManager,
              @Inject(ApiClientHelperService) private apiClientHelper: ApiClientHelperService) {
    this.fileUrl = this.serviceInfoManager.getServiceByName('File')?.url ?? '';
    this.apiResource = this.apiClientHelper.getApiResourceFactory(this.fileUrl);
  }


  uploadFile(file: File, uId: string): Observable<any> {

    const chunks = this.chunkFiles(file);

    return from(chunks).pipe(
      mergeMap((chunk, index) => {
        const formData = new FormData();
        formData.append('uId', uId);
        formData.append('file', chunk, `${file.name}.${index}`);
        return this.http.post<any>(this.apiResource(`${this.controller}/UploadChunk`), formData);
      })
    );
    
  }

  chunkFiles(file: File, chunkSize: number = 1024 * 1024) {
    //chunkSize = 1024 * 1024; // 1MB chunk size
    const totalChunks = Math.ceil(file.size / chunkSize);
    const chunks = [];

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(file.size, start + chunkSize);
      const chunk = file.slice(start, end);
      chunks.push(chunk);
    }
    return chunks;
  }

  /**
   * Saves an attachment to an existing document
   * @param request file and details
   * @returns operation success status
   */
  public SaveAttachment(request: SaveAttachmentRequest): Observable<boolean> {

    let path = this.apiResource(`${this.controller}/Save`);
    let form = new FormData();
    
    const uploadFileOperation = this.uploadFile(request.Attachment as File, request.Details.Uid);
    
    form.append('Details', new Blob([JSON.stringify(request.Details)], { type: "application/json" }));  
    const httpPostOperation = this.http.post<boolean>(path, form);

    return concat(uploadFileOperation, httpPostOperation);
    
  }

  /**
   * Saves a list of temporary files to be uploaded and created as a real attachment later once the document is created
   * @param files list of temp files to be uploaded
   * @returns list of ids corresponding to the uploaded temp files
   */
  public SaveTemporaryAttachments(files: File[], registerId: number, projectId: number): Observable<number[]> {
    if(registerId && projectId) {
      let path = this.apiResource(`${this.controller}/Save/Temp/Register/${registerId}/Project/${projectId}`);
      let observableList = []
      let form = new FormData();

      for(let i = 0; i < files.length; i++) {
        let file = files[i];
        let uId = nanoid(10);
        
        observableList.push(this.uploadFile(file, uId));
        form.append(`fileName${i + 1}`, file.name);
        form.append(`nanoId${i + 1}`, uId);
      }
      
      const httpPostOperation = this.http.post<number[]>(path, form)
      const sequentialExecution = concat(...observableList, httpPostOperation);      

      return sequentialExecution;
    }

    return of([]);
  }

  /**
   * Fetch email in base64 via EWS API
   * @param request Request params for target email to download
   */
  public GetEmailById(request: GetEmailByIdRequest): Observable<GetEmailByIdResponse> {
    let path = this.apiResource(`${this.controller}/EWS/Email`);

    return this.http.post<GetEmailByIdResponse>(path, request);
  }
}
