import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { TokenService, TokenType } from '../../../../services/token.service';
import { ServiceInfoManager } from '../../../../services/service-info-manager.service';
import { OfficeDialogMessage } from '../../../../models/OfficeDialogMessage';
import { ApiClientHelperService } from '../../../../services/api-client-helper.service';
import { AppSettings } from '../../../../models/app-settings';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
})
export class LandingPageComponent implements OnInit {
  imageAlt = 'Scenario Outlook Add-In';
  title = "Welcome to Scenario's Add-In";
  contentTop = `Publish and Upload Emails received directly to the Project via Scenario.  These can be published to Scenario under a new Document Registration, or uploaded to an existing Document.`;
  copyright = '© 2023 Scenario';
  contentBottom = `To use this Add-in, you should have an existing Scenario account to log in.`;
  links: Link[] = [];

  @Output() onLogin = new EventEmitter();

  constructor(@Inject(TokenService) private tokenService: TokenService,
              @Inject(ServiceInfoManager) private serviceInfoManager: ServiceInfoManager,
              @Inject(ApiClientHelperService) private apiClientHelper: ApiClientHelperService)
  {
    window.setTimeout(() => {
      let appSettings: AppSettings = this.apiClientHelper.appSettings;
      this.links = [
        { name: 'Privacy Policy', url: `${appSettings.scenarioCloudUrl}/privacy-policy/` },
        { name: 'Quality Policy', url: `${appSettings.scenarioCloudUrl}/quality-policy/` },
        { name: 'Customer SaaS Agreement', url: `${appSettings.scenarioCloudUrl}/customer-saas-agreement/` }
      ]
    }, 500);
  }

  ngOnInit(): void {
  }

  dialog: Office.Dialog | null = null;
  messageReceived: boolean = false;

  onClickLogin() {
    let self = this;
    let appSettings: AppSettings = this.apiClientHelper.appSettings;
    Office.context.ui.displayDialogAsync(appSettings.globalIdentityUrl, { height: 80, width: 60 }, (result: Office.AsyncResult<Office.Dialog>) => {
      self.dialog = result.value;
      self.dialog.addEventHandler(Office.EventType.DialogMessageReceived, (args) => self.handleMessage(args))
    });
  }

  handleMessage(args: any) {
    if(!this.messageReceived) {
      this.messageReceived = true;
      let message = JSON.parse(args.message) as OfficeDialogMessage;
      this.tokenService.setToken(message.token, TokenType.AuthToken);
      this.serviceInfoManager.storeDiscovery(message.discovery);
      this.serviceInfoManager.storeServerName(message.serverName);
      this.onLogin.emit();
      this.dialog?.close();
    }
  }

  onClickLink(url: string) {
    window.open(url, '_blank');
  }

}


export interface Link {
  name: string;
  url: string;
}
