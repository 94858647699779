import { Injectable } from '@angular/core';

/**
 * Service to interact with localStorage
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  /**
   * Store a value based on a key in local storage
   * @param key localStorage key
   * @param item localStorage value
   */
  store = (key: string, item: string) => {
    localStorage.setItem(key, item);
  }

  /**
   * Retrieve a value based on a key in local storage
   * @param key localStorage key
   * @returns localStorage value
   */
  retrieve = (key: string): string | null => {
    return localStorage.getItem(key);
  }

  /**
   * Remove value based on a key in local storage
   * @param key localStorage key
   */
  remove = (key: string) => {
    localStorage.removeItem(key);
  }

  /**
   * Remove all entries from localStorage
   */
  clear = () => {
    localStorage.clear();
  }
}
