import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService, TokenType } from '../services/token.service';

/**
 * Http interceptor for adding the bearer token to the api request
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  tokenService: TokenService = new TokenService();

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (!this.tokenService.isTokenExpired(TokenType.AuthToken)) {
      let tokenInfo = this.tokenService.getToken(TokenType.AuthToken);
      if (tokenInfo && tokenInfo.token) {
        const cloned = request.clone({
          headers: request.headers.set("Authorization", "Bearer " + tokenInfo.token)
        });
        return next.handle(cloned);
      }
    }

    return next.handle(request);
  }
}
