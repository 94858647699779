import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentStatusSummary, GetDocumentStatusesResponse } from '../models/document/GetDocumentStatusesResponse';
import { SearchDocumentsResponse, DocumentEntitySummary } from '../models/document/SearchDocumentsResponse';
import { GetDocumentRestrictionsResponse, DocumentRestrictionSummary } from '../models/document/GetDocumentRestrictionsResponse';
import { GetAllProjectsResponse, ProjectSummary } from '../models/document/GetAllProjectsResponse';
import { DocumentRegisterSummary, GetRegistersByFiltersResponse } from '../models/document/GetRegistersByFiltersResponse';
import { ApiClientHelperService } from './api-client-helper.service';
import { ServiceInfoManager } from './service-info-manager.service';
import { map, Observable, of } from 'rxjs';


/**
 * Service for interacting with documents
 */
@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  documentUrl: string = '';
  controller: string = 'Documents';
  takeCount: number = 10;

  constructor(@Inject(HttpClient) private http: HttpClient,
              @Inject(ServiceInfoManager) private serviceInfoManager: ServiceInfoManager,
              @Inject(ApiClientHelperService) private apiClientHelper: ApiClientHelperService) {
    this.documentUrl = this.serviceInfoManager.getServiceByName('Document')?.url ?? '';
  }

  /**
   * Gets a list of document status for a specific document
   * @param documentId target document id
   * @returns document status list
   */
  public GetDocumentStatuses(documentId: number): Observable<DocumentStatusSummary[]> {
    let apiResource = this.apiClientHelper.getApiResourceFactory(this.documentUrl);
    let path = apiResource(`${this.controller}/${documentId}/Statuses`);
    return this.http.get<GetDocumentStatusesResponse>(path)
              .pipe(
                map(x => x.statuses)
              )
  }

  /**
   * Searches for documents against their documentNo or subject
   * @param searchText search criteria
   * @returns list of documents found
   */
  public SearchDocuments(searchText: string): Observable<DocumentEntitySummary[]> {
    if(searchText) {
      let apiResource = this.apiClientHelper.getApiResourceFactory(this.documentUrl);

      let searchParams = new URLSearchParams({
        IsAllowedUploadFromOutlook: "true",
        IncludeRevisable: "true",
        IncludeEnabled: "true",
        IsDisableUploadAttachment: "false",
        RegisterAccessLevels: "1",
        IncludeNull: "IsDisableUploadAttachment"
      });

      let path = apiResource(`${this.controller}/Search/${searchText}/Take/${this.takeCount}?${searchParams.toString()}`);
      return this.http.get<SearchDocumentsResponse>(path)
                .pipe(
                  map(x => x.documents)
                )
    }

    return of([]);
  }

  /**
   * Get list of document restrictions/confidentiality
   * @returns document restrictions list
   */
  public GetDocumentRestrictions(): Observable<DocumentRestrictionSummary[]> {
    let apiResource = this.apiClientHelper.getApiResourceFactory(this.documentUrl);
    let path = apiResource(`${this.controller}/Restrictions`);
    return this.http.get<GetDocumentRestrictionsResponse>(path)
              .pipe(
                map(x => x.restrictions)
              )
  }

  /**
   * Get all registers by project
   * @param projectId target project id
   * @returns DocumentRegisterSummary list
   */
   public GetAllRegistersByProject(projectId: number): Observable<DocumentRegisterSummary[]> {
    let apiResource = this.apiClientHelper.getApiResourceFactory(this.documentUrl);

    let searchParams = new URLSearchParams({
      IsAllowedUploadFromOutlook: "true",
      ProjectId: projectId.toString(),
      IncludeRevisable: "true",
      IncludeEnabled: "true",
      IsDisableUploadAttachment: "false",
      RegisterAccessLevels: "1",
      IncludeNull: "IsDisableUploadAttachment"
    });

    let path = apiResource(`${this.controller}/Registers?${searchParams.toString()}`);
    return this.http.get<GetRegistersByFiltersResponse>(path)
              .pipe(
                map(x => x.registers)
              )
  }

  /**
   * Get all registers by id
   * @param registerId target register id
   * @returns DocumentRegisterSummary list
   */
   public GetRegistersById(registerId: number): Observable<DocumentRegisterSummary[]> {
    let apiResource = this.apiClientHelper.getApiResourceFactory(this.documentUrl);

    let searchParams = new URLSearchParams({
      IsAllowedUploadFromOutlook: "true",
      RegisterIds: registerId.toString(),
      IncludeRevisable: "true",
      IncludeEnabled: "true",
      IsDisableUploadAttachment: "false",
      RegisterAccessLevels: "1",
      IncludeNull: "IsDisableUploadAttachment"
    });

    let path = apiResource(`${this.controller}/Registers?${searchParams.toString()}`);
    return this.http.get<GetRegistersByFiltersResponse>(path)
              .pipe(
                map(x => x.registers)
              )
  }

  /**
   * Get all projects
   * @returns Projects list
   */
  public GetAllProjects(): Observable<ProjectSummary[]> {
    let apiResource = this.apiClientHelper.getApiResourceFactory(this.documentUrl);
    let path = apiResource(`${this.controller}/Projects`);
    return this.http.get<GetAllProjectsResponse>(path)
              .pipe(
                map(x => x.projects)
              )
  }
}
