import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ApiClientHelperService } from '../../services/api-client-helper.service';
import { ServiceInfoManager } from '../../services/service-info-manager.service';
import { TokenService, TokenType } from '../../services/token.service';

@Component({
  selector: 'app-home',
  templateUrl: './app.component.html',
})
export default class AppComponent implements OnInit {
  loggedIn: boolean = false;

  tokenService: TokenService = new TokenService();

  constructor(@Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
            @Inject(ServiceInfoManager) private serviceInfoManager: ServiceInfoManager,
            @Inject(ApiClientHelperService) private apiClientHelper: ApiClientHelperService)
  {
    this.apiClientHelper.loadAppSettings().subscribe(_ => _);
  }

  ngOnInit(): void {
    this.redirectIfNotExpired();
  }

  async run() {

  }

  redirectIfNotExpired() {
    let authTokenExpired = this.tokenService.isTokenExpired(TokenType.AuthToken);

    if(!authTokenExpired) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
      this.tokenService.removeToken(TokenType.AuthToken);
    }

    this.cdr.detectChanges();
  }

  onLogin() {
    this.loggedIn = true;
    this.serviceInfoManager.storeReloadCount(0);
    this.cdr.detectChanges();
  }

  onLogout() {
    this.loggedIn = false;
    this.serviceInfoManager.storeReloadCount(0);
    this.tokenService.removeToken(TokenType.AuthToken);
    this.cdr.detectChanges();
  }
}
