import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RefreshTokenRequest } from '../models/auth/RefreshTokenRequest';
import { RefreshTokenResponse } from '../models/auth/RefreshTokenResponse';
import { TokenAuthenticateResponse } from '../models/auth/TokenAuthenticateResponse';
import { ApiClientHelperService } from './api-client-helper.service';
import { ServiceInfoManager } from './service-info-manager.service';

/**
 * Service to interact with auth related operations
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authUrl: string = '';
  controller: string = 'Authenticate';
  apiResource: (_: string) => string = () => '';

  constructor(@Inject(HttpClient) private http: HttpClient,
              @Inject(ServiceInfoManager) private serviceInfoManager: ServiceInfoManager,
              @Inject(ApiClientHelperService) private apiClientHelper: ApiClientHelperService) {
    this.authUrl = this.serviceInfoManager.getServiceByName('Auth')?.url ?? '';
    this.apiResource = this.apiClientHelper.getApiResourceFactory(this.authUrl);
  }

  /**
   * Create a redirect token
   * @returns Redirect token
   */
  public CreateRedirect(): Observable<TokenAuthenticateResponse> {
    let path = this.apiResource(`${this.controller}/redirect`);
    return this.http.post<TokenAuthenticateResponse>(path, {});
  }

  /**
   * Refresh the current user's token
   * @param request request object with current token
   * @returns response object with new expiry date
   */
  public RefreshToken(request: RefreshTokenRequest): Observable<RefreshTokenResponse> {
    let path = this.apiResource(`${this.controller}/refreshtoken`);
    return this.http.post<RefreshTokenResponse>(path, request);
  }

}
