import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { IDropdownOptions, ControlInformationType, ISelectedValue, IDropdownValue } from 'scenario-components';
import { DocumentEntitySummary } from '../../../../models/document/SearchDocumentsResponse';
import { DocumentService } from '../../../../services/document.service';
import { AuthService } from '../../../../services/auth.service';
import { TokenService, TokenType } from '../../../../services/token.service';
import { FileService } from '../../../../services/file.service';
import { SaveAttachmentRequest } from '../../../../models/file/SaveAttachmentRequest';
import { ServiceInfoManager } from '../../../../services/service-info-manager.service';
import { RefreshTokenRequest } from '../../../../models/auth/RefreshTokenRequest';
import { DocumentStatusSummary } from '../../../../models/document/GetDocumentStatusesResponse';
import { DocumentRegisterSummary } from '../../../../models/document/GetRegistersByFiltersResponse';
import { debounce } from 'lodash'
import { GetEmailByIdRequest } from '../../../../models/file/GetEmailByIdRequest';
import { nanoid } from 'nanoid'
import { concat, forkJoin, last, map, mergeMap, of } from 'rxjs';

@Component({
  selector: 'app-upload-page',
  templateUrl: './upload-page.component.html',
})
export class UploadPageComponent implements OnInit {

  ControlInformationType = ControlInformationType;

  sectionLabelExistingDocument: string = 'Existing Document: Upload';
  sectionLabelNewDocument: string = 'New Document: Register & Upload';
  labelQuickSearch: string = 'Quick Search';
  labelDocumentSubject: string = 'Document Subject';
  labelAttachmentComments: string = 'Attachment Comments';
  labelRestrictedBy: string = 'Restricted To';
  labelStatus: string = 'Attachment Status';
  labelProject: string = 'Project';
  labelRegister: string = 'Process Register';
  errorMessage: string = 'Something went wrong. Email failed to upload.';

  RestrictionDropdownOptions: IDropdownOptions = { header: [], content: [] };
  ProjectDropdownOptions: IDropdownOptions = { header: [], content: [] };
  RegisterDropdownOptions: IDropdownOptions = { header: ['Code', 'Description'], content: [] };
  StatusDropdownOptions: IDropdownOptions = { header: ['Code', 'Description'], content: [] };
  disabledTop: boolean = false;
  disabledBottom: boolean = false;
  disabledTopButton: boolean = false;
  disabledBottomButton: boolean = false;
  isLoadingUpload: boolean = false;
  showSuccessExisting: boolean = false;
  showSuccessNew: boolean = false;
  showError: boolean = false;
  showAlways: boolean = true;
  TextSearch: string = '';
  TextSubject: string = '';
  TextComments: string = '';
  selectedRestriction: ISelectedValue =  { Id: [], Value: '', Contents: [] };
  selectedStatus: ISelectedValue =  { Id: [], Value: '', Contents: [] };
  selectedRegister: ISelectedValue =  { Id: [], Value: '', Contents: [] };
  selectedProject: ISelectedValue = { Id: [], Value: '', Contents: [] };
  selectedDocumentId: number = 0;
  email: File | null = null;
  serverName: string = '';
  enterpriseDocumentLink: string = '';
  refreshTokenTimeoutDelay: number = 600 * 1000 // 600 seconds = 10mins
  refreshTokenTimeoutId: number = 0;
  selectedRegisterEntity: DocumentRegisterSummary | null = null;

  ShowSearchContent: boolean = false;
  dataDocument: DocumentEntitySummary[] = [];
  docStatuses: DocumentStatusSummary[] = [];
  registers: DocumentRegisterSummary[] = [];

  @Output() onLogout = new EventEmitter();

  constructor(@Inject(DocumentService) private documentService: DocumentService,
              @Inject(FileService) private fileService: FileService,
              @Inject(AuthService) private authService: AuthService,
              @Inject(TokenService) private tokenService: TokenService,
              @Inject(ServiceInfoManager) private serviceInfoManager: ServiceInfoManager,
              @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef) {
    this.onSearch = debounce(this.onSearch, 350);
  }

  ngOnInit(): void {
    let reloadCount = this.serviceInfoManager.getReloadCount();
    if(reloadCount == 0) {
      location.reload();
      this.serviceInfoManager.storeReloadCount(++reloadCount);
    }

    this.refreshTokenTimeoutId = window.setTimeout(this.refreshToken, this.refreshTokenTimeoutDelay);

    this.isLoadingUpload = true;
    this.serverName = this.serviceInfoManager.getServerName();

    this.onApiRequestWrapper(() =>
      this.documentService
          .GetDocumentRestrictions()
          .subscribe({
            next: x => {
              this.RestrictionDropdownOptions.content = x.map(i => ({
                id: i.id,
                value: [i.name],
                isActive: false,
                isDisabled: false,
              }));

              this.reloadPageIfUiNotRenderedCorrectly();
            },
            error: err => this.onApiRequestError(err),
            complete: () => this.onApiRequestComplete()
          })
    );


    this.onApiRequestWrapper(() =>
      this.documentService
          .GetAllProjects()
          .subscribe({
            next: x => {
              let dropdownItems =  x.map(i => ({
                id: i.id,
                value: [`${i.projectNo} - ${i.name}`],
                isActive: false,
                isDisabled: false,
              }));

              this.ProjectDropdownOptions = { header: [], content: dropdownItems };

            },
            error: err => this.onApiRequestError(err),
            complete: () => this.onApiRequestComplete()
        })
    );

    this.resetFields();

    this.cdr.detectChanges();
  }

  reloadPageIfUiNotRenderedCorrectly() {
    setTimeout(() => {
      const el = document.querySelector('tr.options-table.ng-star-inserted td.ng-star-inserted span');
      if (!el) return;

      const elementText = el.textContent;
      if (elementText == '{{contentItem}}')
        this.refreshPage();
    }, 500);
  }

  resetFields() {
    this.TextSearch = '';
    this.TextSubject = '';
    this.TextComments = '';
    this.selectedProject =  { Id: [], Value: '', Contents: [] };
    this.selectedRegister =  { Id: [], Value: '', Contents: [] };
    this.selectedRestriction =  { Id: [], Value: '', Contents: [] };
    this.selectedStatus =  { Id: [], Value: '', Contents: [] };
    this.RegisterDropdownOptions.content = [];
    this.StatusDropdownOptions.content = [];
    this.disabledTop = false;
    this.disabledBottom = false;
    this.disabledBottomButton = true;
    this.disabledTopButton = true;
  }

  refreshPage() {
    location.reload();
  }

  logout() {
    this.onLogout.emit();
  }

  onSearch(text: string) {
    if(!text || text == '') {
      this.TextSubject = '';
    }

    if(text && text != '') {
      this.isLoadingUpload = true;

      this.onApiRequestWrapper(() =>
        this.documentService
            .SearchDocuments(text)
            .subscribe({
              next: x => {
                this.dataDocument = x;
                this.ShowSearchContent = true;
              },
              error: err => this.onApiRequestError(err),
              complete: () => this.onApiRequestComplete()
            })
      );
    } else {
      this.ShowSearchContent = false;
    }
    this.setControls();
  }

  onPageClick() {
    this.ShowSearchContent = false;
  }

  onListItemClick(data: any, document: DocumentEntitySummary) {
    this.TextSearch = data.PrimaryText;
    this.TextSubject = data.SecondaryText;
    this.ShowSearchContent = false;
    this.selectedDocumentId = document.id;

    this.isLoadingUpload = true;

    // fetch register for file limit check
    this.onApiRequestWrapper(() =>
      this.documentService
          .GetRegistersById(document.registerId)
          .subscribe({
            next: x => {
              if(x != null && x.length > 0) {
                this.selectedRegisterEntity = x[0];
              } else {
                this.selectedRegisterEntity = null;
              }
            },
            error: err => this.onApiRequestError(err),
            complete: () => this.onApiRequestComplete()
          })
    );

    this.onApiRequestWrapper(() =>
      this.documentService
          .GetDocumentStatuses(document.id)
          .subscribe({
            next: x => {
              this.docStatuses = [...x];

              this.StatusDropdownOptions.content = x.map(i => ({
                id: i.id,
                value: [i.code, i.description],
                isActive: false,
                isDisabled: false,
              }))

              // assign doc status to attachment status
              let targetStatus = this.StatusDropdownOptions.content.find(i => i.id == document.documentStatusId);
              let targetDocStatus = this.docStatuses.find(i => i.id == document.documentStatusId);
              this.selectedStatus = {
                Id: [targetStatus?.id ?? 0],
                Value: targetStatus?.value[0] ?? '',
                Contents: [targetStatus ?? <IDropdownValue>{}]
              };
              //this.selectedStatusId = targetStatus?.id ?? 0;
              let baseStatus = targetDocStatus?.baseStatus ?? 0;

              // assign default restriction based on status
              let targetRestriction =  this.RestrictionDropdownOptions.content.find(i => i.value[0] == 'Public');

              if(baseStatus == 0) {
                targetRestriction = this.RestrictionDropdownOptions.content.find(i => i.value[0] == 'My Company');
              }

              this.selectedRestriction = {
                Id: [targetRestriction?.id ?? 0],
                Value: targetRestriction?.value[0] ?? '',
                Contents: [targetRestriction ?? <IDropdownValue>{}]
              };
            },
            error: err => this.onApiRequestError(err),
            complete: () => this.onApiRequestComplete()
          })
    );


    this.setControls();
  }

  onComments(text: string) {
    this.setControls();
  }

  onSelectProject($event: ISelectedValue) {
    if(!$event) {
      return;
    }

    if(this.selectedProject.Id.length == 0) {
      return;
    }

    this.isLoadingUpload = true;

    this.onApiRequestWrapper(() =>
      this.documentService
          .GetAllRegistersByProject(this.selectedProject.Id[0])
          .subscribe({
            next: x => {
              this.registers = [...x];

              this.RegisterDropdownOptions.content = x.map(i => ({
                id: i.id,
                value: [i.code, i.description],
                isActive: false,
                isDisabled: false,
              }))
            },
            error: err => this.onApiRequestError(err),
            complete: () => this.onApiRequestComplete()
        })
    );


    this.setControls();
  }

  onSelectRestriction($event: ISelectedValue) {
    if(!$event) {
      return;
    }

    this.setControls();
  }

  onSelectStatus($event: ISelectedValue) {
    if(!$event) {
      return;
    }

    this.setControls();
  }

  onSelectRegister($event: ISelectedValue) {
    if(!$event) {
      return;
    }

    this.setControls();
  }

  setControls() {
    if (this.isLoadingUpload)
      return;
    
    this.setDisabled();
    this.setEnabled();
    this.showError = false;
    this.showSuccessExisting = false;
    this.showSuccessNew = false;
  }

  setDisabled() {
    if(this.TextComments != '' ||
      this.TextSearch != '' ||
      this.selectedStatus.Id.length > 0 ||
      this.selectedRestriction.Id.length > 0) {
      this.disabledBottom = true;
    } else {
      this.disabledBottom = false;
    }

    if(this.selectedProject.Id.length > 0 ||
      this.selectedRegister.Id.length > 0) {
      this.disabledTop = true;
    } else {
      this.disabledTop = false;
    }
  }

  setEnabled() {
    if(this.TextSearch != '' &&
      this.TextSubject != '' &&
      this.selectedStatus.Id.length > 0 &&
      this.selectedRestriction.Id.length > 0) {
      this.disabledTopButton = false;
    } else {
      this.disabledTopButton = true;
    }

    if(this.selectedProject.Id.length > 0 &&
      this.selectedRegister.Id.length > 0) {
      this.disabledBottomButton = false;
    } else {
      this.disabledBottomButton = true;
    }

  }

  isValidFileSize(register: DocumentRegisterSummary | null | undefined): boolean {
    if(register && register.maxFileUploadSize && this.email) {
      let maxFileSize = register.maxFileUploadSize * 1024 * 1024; // convert to bytes
      if(this.email.size > maxFileSize) {
        this.errorMessage = `File size exceeds the Register's limit (${register.maxFileUploadSize} MB)`;

        this.showError = true;
        this.cdr.detectChanges();

        return false;
      }
    }

    return true;
  }

  isWindowsOrChrome() {
    if (navigator.appVersion.indexOf("Win") != -1) {
      return true;
    }
    else if (navigator.appVersion.indexOf("Chrome") != -1) {
      return true;
    }
    else {
      return false;
    }
  }

  onSubmitExisting() {
    this.onSubmitExistingGo(this);
  }

  onSubmitExistingGo(submitExistingContext: UploadPageComponent) {

    submitExistingContext.isLoadingUpload = true;

    submitExistingContext.getEmail(() => {

      // cancel operation on fail
      if(!submitExistingContext.isValidFileSize(submitExistingContext.selectedRegisterEntity)) {
        return;
      }

      let request: SaveAttachmentRequest = {
        Attachment: submitExistingContext.email,
        Details: {
          FileName: submitExistingContext.email!.name,
          Uid: nanoid(10),
          DocumentId: submitExistingContext.selectedDocumentId,
          AttachmentComments: submitExistingContext.TextComments,
          DocumentStatusId: submitExistingContext.selectedStatus.Id[0],
          Restriction: submitExistingContext.selectedRestriction.Id[0]
        }
      }

     submitExistingContext.onApiRequestWrapper(() =>
      concat(submitExistingContext.fileService.SaveAttachment(request),  
            submitExistingContext.authService.CreateRedirect())
            .pipe(last())
            .subscribe({
              next: (result) => {
                submitExistingContext.wrapUpSubmitExisting(result);                
              },
              error: err => this.onApiRequestError(err)
            })
        );
    });
  }

  wrapUpSubmitExisting(result: any) {
    let enterpriseUrl = this.serviceInfoManager.getServiceByName('Enterprise')?.url ?? '';

    // normalized enterprise base URL
    let baseUrl = enterpriseUrl.slice(-1) == '/' ?
                  enterpriseUrl.slice(0, -1) :
                  enterpriseUrl

    // enterprise document URL
    let redirectUrl = `${baseUrl}/STAND0017/Document/${this.selectedDocumentId}`;

    // add query params to auth redirect
    let documentUrl = new URL(baseUrl);
    documentUrl.searchParams.set('redirectToken', result.token);
    documentUrl.searchParams.set('redirectUrl', redirectUrl.toString());
    documentUrl.searchParams.set('username', result.username);
    documentUrl.searchParams.set('userId', result.userId.toString());

    this.enterpriseDocumentLink = documentUrl.toString();
    
    this.resetFields();

    if (this.isWindowsOrChrome()) {
      this.showSuccessExisting = true;
      this.onApiRequestComplete();
    }
    else {
      setTimeout(() => {
        this.showSuccessExisting = true;
        this.onApiRequestComplete();
      }, 500);
    }
  }

  onSubmitNew() {
    this.isLoadingUpload = true;

    this.getEmail(() => {

      let targetRegister = this.registers.find(x => x.id == this.selectedRegister.Id[0]);

      // cancel operation on fail
      if(!this.isValidFileSize(targetRegister)) {
        this.cdr.detectChanges();
        return;
      }

      this.onApiRequestWrapper(() => {

        const saveTemporaryAttachments$ = this.fileService.SaveTemporaryAttachments([this.email as File], this.selectedRegister.Id[0], this.selectedProject.Id[0]);        
        const redirect$ = forkJoin([saveTemporaryAttachments$.pipe(last()), this.authService.CreateRedirect()]);

        redirect$.subscribe({
            next: ([tempFileIds, result]) => {                
              this.showSuccessNew = true;
              let enterpriseUrl = this.serviceInfoManager.getServiceByName('Enterprise')?.url ?? '';

              // normalized enterprise base URL
              let baseUrl = enterpriseUrl.slice(-1) == '/' ?
              enterpriseUrl.slice(0, -1) :
              enterpriseUrl

              // redirect to enterprise
              let redirect = `${baseUrl}/STAND0017/New`;

              // add query params to future route redirect after auth redirect
              let redirectUrl = new URL(redirect);
              redirectUrl.searchParams.set('registerId', this.selectedRegister.Id[0].toString());
              redirectUrl.searchParams.set('projectId', this.selectedProject.Id[0].toString());
              redirectUrl.searchParams.set('tempFileIds', tempFileIds.join(","));

              // add query params to auth redirect
              let url = new URL(baseUrl);
              url.searchParams.set('redirectToken', result.token);
              url.searchParams.set('redirectUrl', redirectUrl.toString());
              url.searchParams.set('username', result.username);
              url.searchParams.set('userId', result.userId.toString());

              try {
                Office.context.ui.openBrowserWindow(url.toString());
              }
              catch 
              {
                window.open(url.toString(), '_blank');
              }

              this.resetFields();

              if (this.isWindowsOrChrome()) {
                this.showSuccessNew = true;
                this.onApiRequestComplete();
              }
              else {
              setTimeout(() => {
                this.showSuccessNew = true;
                this.onApiRequestComplete();
              }, 500);
              }
            },
            error: err => this.onApiRequestError(err)
          })
        });
    });
  }

  resetTimerForRefreshToken() {
    window.clearTimeout(this.refreshTokenTimeoutId);
    this.refreshTokenTimeoutId = window.setTimeout(this.refreshToken, this.refreshTokenTimeoutDelay);
  }

  refreshToken() {
    let authTokenExpired = this.tokenService.isTokenExpired(TokenType.AuthToken);

    // not expired
    if(!authTokenExpired) {
      let token = this.tokenService.getToken(TokenType.AuthToken);

      // if token exists
      if(token) {
        let request: RefreshTokenRequest = {
          Username: '',
          Token: token.token
        }

        // refresh token and update expiry
        this.authService
            .RefreshToken(request)
            .subscribe(x => {
              this.tokenService.updateExpiry(new Date(x.newTokenExpiry));
            });
      }
    }
  }

  getEmail(action: () => void) {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: false }, (result: Office.AsyncResult<string>) => {
      let token = result.value;
      let request: GetEmailByIdRequest = {
        Token: token,
        EmailItemId: Office.context.mailbox.item?.itemId ?? '',
        EWSUrl: Office.context.mailbox.ewsUrl
      };

      this.onApiRequestWrapper(() => {
        this.fileService
          .GetEmailById(request)
          .subscribe({
            next: x => {
              let fileName = x.subject + ".eml";
              let blob = this.convertBase64ToBlob(x.base64Data);
              this.email = new File([blob], fileName);

              if(action) {
                action();
              }
            },
            error: err => this.onApiRequestError(err),
            //complete: () => this.onApiRequestComplete()
          });
      })
    });
  }

  // old way via client-side
  /*getEmail(action: () => void) {
    let request = this.getEmailQuery(Office.context.mailbox.item?.itemId);
    let envelope = this.getSoapEnvelopeQuery(request);
    Office.context.mailbox.makeEwsRequestAsync(envelope, (result) => {
      let parser = new DOMParser();
      let doc = parser.parseFromString(result.value, "text/xml");
      let values = doc.getElementsByTagName("t:MimeContent");
      let subject = doc.getElementsByTagName("t:Subject");
      let fileName = subject[0].textContent + ".eml";
      let blob =  this.convertBase64ToBlob(values[0].textContent);
      this.email = new File([blob], fileName);

      if(action) {
        action();
      }
     });
  }*/

  /**
   * Wrapper to perform auth token check before executing the api request
   * @param action action to be executed
   * @returns return of the action
   */
  onApiRequestWrapper(action: () => any): any {
    try {
      let authTokenExpired = this.tokenService.isTokenExpired(TokenType.AuthToken);

      this.cdr.detectChanges();

      if(authTokenExpired) {
        this.tokenService.removeToken(TokenType.AuthToken);
        this.onLogout.emit();
        return;
      }

      if(action) {
        return action();
      }
    }
    catch(err) {
      console.log(err);
      this.isLoadingUpload = false;
      this.cdr.detectChanges();
    }
  }

  onApiRequestComplete() {
    this.isLoadingUpload = false;

    // reset timer if there was activity
    this.resetTimerForRefreshToken();

    this.cdr.detectChanges();
  }

  onApiRequestError(err: any) {
    this.setControls();
    this.errorMessage = 'Something went wrong. Email failed to upload.';
    this.showError = true;
    //  this.isLoadingUpload = false;
    this.cdr.detectChanges();

    if(err.status && err.status == 401) {
      this.tokenService.removeToken(TokenType.AuthToken);
      this.onLogout.emit();
      return;
    }

    return;
  }

  convertBase64ToBlob(base64String: string | null, contentType: string = ''): Blob {
    if(!base64String) {
      return new Blob();
    }

    let sliceSize = 1024;
    let byteCharacters = atob(base64String);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);

        let bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  getEmailQuery(emailId: string | undefined) {
    let query =
    '  <GetItem xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">' +
    '    <ItemShape>' +
    '      <t:BaseShape>IdOnly</t:BaseShape>' +
    '      <t:IncludeMimeContent>true</t:IncludeMimeContent>' +
    '      <AdditionalProperties xmlns="http://schemas.microsoft.com/exchange/services/2006/types">' +
    '        <FieldURI FieldURI="item:Subject" />' +
    '      </AdditionalProperties>' +
    '    </ItemShape>' +
    '    <ItemIds>' +
    '      <t:ItemId Id="' + emailId + '" />' +
    '    </ItemIds>' +
    '  </GetItem>';

    return query;
  }

  getSoapEnvelopeQuery(request: string) {
     // Wrap an Exchange Web Services request in a SOAP envelope.
     let query =

     '<?xml version="1.0" encoding="utf-8"?>' +
     '<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
     '               xmlns:xsd="http://www.w3.org/2001/XMLSchema"' +
     '               xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"' +
     '               xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
     '  <soap:Header>' +
     '    <RequestServerVersion Version="Exchange2013" xmlns="http://schemas.microsoft.com/exchange/services/2006/types" soap:mustUnderstand="0" />' +
     '  </soap:Header>' +
     '  <soap:Body>' +

     request +

     '  </soap:Body>' +
     '</soap:Envelope>';

     return query;
  }

}
