import { Injectable, Inject } from '@angular/core';
import { AppSettings } from '../models/app-settings';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

/**
 * Helper service for generating endpoint paths
 */
@Injectable({
  providedIn: 'root'
})
export class ApiClientHelperService {

  public appSettings: AppSettings;
  public appSettingsAreLoaded: boolean;

  constructor(@Inject(HttpClient) private http: HttpClient) {
    this.appSettings = new AppSettings();
    this.appSettingsAreLoaded = false;
  }

  /**
   * Load appSettings
   * @returns AppSettings model
   */
   loadAppSettings = (): Observable<AppSettings> => {
    var o = this.http
      .get<AppSettings>('/assets/appsettings.json')
      .pipe(
        tap(data => {
          if (data) {
            this.appSettings = data;
            this.appSettingsAreLoaded = true;
          }
        })
      );
    return o;
  }

  /**
   * Generate endpoint path for a specific base URL
   * @param baseUrl target starting point of URL
   * @returns generated endpoint = baseURL + path
   */
  getApiResourceFactory(baseUrl: string): (path: string) => string {
    let base = baseUrl;
    return (path: string): string =>  {
      if (base.endsWith('/') == false) base = base + '/';
      return `${base}${path}`;
    }
  }
}
