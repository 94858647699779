import { Injectable } from '@angular/core';
import { GenericTokenResponse } from '../models/auth/GenericTokenResponse';
import { StorageService } from './storage.service';

/**
 * Service to perform operations with tokens
 */
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  storageService: StorageService = new StorageService();

  constructor() { }

  /**
   * Stores the token in local storage
   * @param tokenInfo token object
   * @param tokenType token type
   */
  public setToken(tokenInfo: GenericTokenResponse, tokenType: TokenType) {
    if (tokenInfo && tokenInfo.token && tokenInfo.expires) {
      let tokenKey = TokenType[tokenType].toString();
      let tokenString = JSON.stringify(tokenInfo);
      this.storageService.store(tokenKey, tokenString);
    }
  }

  /**
   * Fetches the stored token in local storage
   * @param tokenType token type
   * @returns token or null
   */
  public getToken(tokenType: TokenType): GenericTokenResponse | null {
    let tokenKey = TokenType[tokenType].toString();
    let tokenString: string | null = this.storageService.retrieve(tokenKey);
    let tokenObj: GenericTokenResponse | null = null;

    if(tokenString && tokenString != 'undefined') {
      tokenObj = JSON.parse(tokenString);
    }

    if(tokenObj) {
      return tokenObj;
    }

    return null;
  }

  /**
   * Checks if token is expired
   * @param tokenType token type
   * @returns flag if token is expired or not
   */
  public isTokenExpired(tokenType: TokenType): boolean {
    let token = this.getToken(tokenType);

    if(token) {
      let now = new Date().toISOString();
      return new Date(now) > new Date(token.expires);
    }

    return true;
  }

  /**
   * Remove auth token if expired
   */
  public removeIfExpired() {
    let tokenInfo = this.getToken(TokenType.AuthToken);
    if (!tokenInfo) return;

    // If not expired, do nothing, early exit
    var now = new Date().toISOString();
    if (new Date(tokenInfo.expires) > new Date(now)) return;

    this.removeToken(TokenType.AuthToken);
  }

  /**
   * Remove a specific token based on type
   * @param tokenType token type
   */
  public removeToken(tokenType: TokenType) {
    let tokenKey = TokenType[tokenType].toString();
    this.storageService.remove(tokenKey);
  }

  /**
   * Update auth token expiration
   * @param expires new expiration
   */
  public updateExpiry(expires: Date) {
    let tokenInfo = this.getToken(TokenType.AuthToken);
    if(tokenInfo) {
      tokenInfo.expires = expires;
      this.setToken(tokenInfo, TokenType.AuthToken);
    }
  }
}

export enum TokenType {
  AuthToken,
  MFASetup,
  MFAChallenge,
}
