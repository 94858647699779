import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ComponentsModule } from 'scenario-components';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import AppComponent from './app.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { UploadPageComponent } from './components/upload-page/upload-page.component';
import { TokenInterceptor } from '../../interceptors/token.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    UploadPageComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ComponentsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export default class AppModule { }
